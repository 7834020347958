package com.deange.nastychristmas.ui.resources

val StringResources.Companion.EN
  get() = StringResources(
    add = "Add",
    appName = "Nasty Christmas",
    autoScrollSpeedHint = "Auto-scroll speed",
    back = "Back",
    clear = "Clear",
    confirm = "Confirm",
    confirmResetApp = "Confirm Reset App",
    editGiftNames = "Edit Gift Names",
    enterGameCode = "Enter Game Code",
    errorGiftExists = "A gift with that name has already been opened.",
    enforceOwnershipDescription = "Require that you cannot steal a gift you have already had in the same round.",
    enforceOwnershipTitle = "Enforce Ownership",
    giftHint = "Gift Name",
    hideUnstealableGifts = "Hide unstealable gifts",
    letsGetNasty = "Let’s Get Nasty!",
    nastyGiftPromo = "Follow along at www.nasty.gift",
    nastyGiftPromoWithGameCode = "Follow along at www.nasty.gift\nGame Code: %s",
    newGame = "New Game",
    notApplicable = "N/A",
    ok = "OK",
    openGiftEndGame = "This finishes the game!",
    openGiftEndRound = "This finishes the current round.",
    openGiftRoundTitle = "Round %d: %s is opening…",
    openLastGiftTitle = "Open Last Gift",
    openNewGiftTitle = "Open New Gift",
    playerHint = "Player Name",
    addingPlayers = "Adding players…",
    remove = "Remove",
    removePlayer = "Remove %s",
    resetApp = "Reset App",
    round = "Round %d",
    roundSelectionTitle = "Selecting player for Round %d…",
    roundTitle = "Going next for Round %d is…",
    settings = "Settings",
    showUnstealableGifts = "Show unstealable gifts",
    statMostOpens = "Most Gifts Opened",
    statMostSteals = "Biggest Stealer",
    statMostStolenFrom = "Most Stolen From",
    statMostStolenGift = "Most Stolen Gift",
    stealFrom = "Steal from %s",
    stealRoundTitle = "%s is able to…",
    thanksForPlaying = "Thanks for playing!",
    undo = "Undo",
  )
